<template>
  <div class="co-container-sm py-7 sm:py-4 md:py-12">
    <article class="mx-4 bg-co-gray-50 shadow-[0_0_4px_rgba(51,51,51,0.2)] md:m-0">
      <div class="p-5 pt-12 sm:p-12">
        <h1 class="co-display-6 mb-5 border-b-[3px] border-co-gray-200 pb-4 text-center font-bold tracking-normal">
          無料相談がうまくいかない場合の対処方法
        </h1>
        <p class="mb-5 ml-4">
          無料相談をご予約いただきありがとうございます。<br>ご予約当日はURLを開いて入室するだけで参加可能な「かんたんビデオチャット」をご利用いただきます。万が一、接続できない場合にこちらのページを参考にご対応ください。<br>スマートフォンからのご参加の方は「2.かんたんビデオチャットの利用方法」からご確認ください。
        </p>
        <h2 class="co-display-8 mb-8 mt-14 border-l-[5px] border-[#ff0033] py-2 pl-4 pr-2 font-bold tracking-normal">
          目次
        </h2>
        <ol class="my-5 ml-4 list-decimal">
          <li class="mb-2 ml-10 md:ml-12">
            対応ブラウザのインストール（必要ある場合のみ）
          </li>
          <li class="mb-2 ml-10 md:ml-12">
            かんたんビデオチャットの利用方法
          </li>
        </ol>
        <h2 class="co-display-8 mb-8 mt-14 border-l-[5px] border-[#ff0033] py-2 pl-4 pr-2 font-bold tracking-normal">
          対応ブラウザのインストール
        </h2>
        <p class="mb-5 ml-4">
          無料相談で利用するかんたんビデオチャットは以下のブラウザで動きます。
        </p>
        <ul class="my-5 ml-4 list-disc">
          <li class="mb-2 ml-10 md:ml-12">
            【推奨】Google Chrome
          </li>
          <li class="mb-2 ml-10 md:ml-12">
            Edge ※ Internet Explorer では動作しません
          </li>
          <li class="mb-2 ml-10 md:ml-12">
            Safari
          </li>
          <li class="mb-2 ml-10 md:ml-12">
            Firefox
          </li>
          <li class="mb-2 ml-10 md:ml-12">
            Opera
          </li>
        </ul>
        <p class="mb-5 ml-4">
          上記以外のブラウザをご利用の方は、推奨であるGoogle Chrome をインストールし、以降は Google Chrome をご利用ください。
        </p>
        <div
          class="mb-4 ml-4 rounded border border-[#06C755] bg-[#d7f8e4] px-5 py-3 text-[#00a13b]"
          role="alert"
        >
          <i
            class="fa fa-exclamation-triangle mr-2"
            aria-hidden="true"
          />実際の学習時は、当社にて動作確認ができているGoogle Chromeでの実施となります。予めご了承ください
        </div>
        <p class="mb-5 ml-4">
          WindowsとMacでインストール方法が少し異なりますので、順番にご紹介します。
        </p>
        <div
          class="mb-4 ml-4 rounded border border-[#06c755] bg-[#d7f8e4] px-5 py-3 text-[#00a13b]"
          role="alert"
        >
          <i
            class="fa fa-exclamation-triangle mr-2"
            aria-hidden="true"
          />対象ブラウザをすでにインストールされている場合は飛ばして構いません。
        </div>
        <h3 class="co-display-10 mb-5 mt-10 font-bold">
          Windowsの場合
        </h3>
        <p class="mb-5 ml-4">
          Windowsの場合のインストール方法を紹介します。
        </p>
        <p class="mb-5 ml-4">
          <a
            href="https://www.google.co.jp/chrome/"
            target="_blank"
          >Google Chromeのダウンロードページ</a>へアクセスしてください。<br><strong>Chromeをダウンロード</strong>のボタンをクリックします。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/0-03.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          規約を読んだら、<strong>同意してインストール</strong>しましょう。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/0-04.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          ダウンロードが始まります。すると、<strong>ChromeSetUp.exeのダウンロードが終了しました</strong>と出るので、<strong>実行</strong>をクリックしましょう。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/0-05.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          下記のように、インストールが実行されます。少し時間がかかるので待ちましょう。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/0-06.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          インストールされると、自動的にGoogle Chromeが開きます。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/0-07.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          以上でセットアップは終了です。
        </p>
        <h3 class="co-display-10 mb-5 mt-10 font-bold">
          Macの場合
        </h3>
        <p class="mb-5 ml-4">
          Macの場合のインストール方法を紹介します。
        </p>
        <p class="mb-5 ml-4">
          <a
            href="https://www.google.co.jp/chrome/"
            target="_blank"
          >Google Chromeのダウンロードページ</a>へアクセスしてください。<br><strong>Chromeをダウンロード</strong>のボタンをクリックします。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/chrome_01.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
          >
        </p>
        <p class="mb-5 ml-4">
          規約を読んだら、<strong>同意してインストール</strong>しましょう。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/chrome_02.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          ダウンロードが始まります。ダウンロードが開始されない場合は、下方に小さく書かれた<strong>こちら</strong>をクリックしてもう一度試してみてください。<br>ダウンロードが完了したらインストーラーをクリックします。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/chrome_03.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          下記のようなウィンドウが開きますので、ドラッグ＆ドロップでインストールしましょう。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/chrome_05.png"
            class="co-aspectratio-attr mb-4"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          インストールが完了すると、Macの場合は<strong>アプリケーション</strong>にアプリが入っているはずです。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/chrome_06.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          毎回このアプリケーションフォルダを開いて、アプリを開いても良いですが、MacにはDock（ドック）がありますので、ここに置いておくとアプリを起動しやすいでしょう。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/chrome_07.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          それでは、Google Chromeを開いてみましょう。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/chrome_04.png"
            class="co-aspectratio-attr mb-4"
            style="width: 700px"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          このような画面が開いたら完了です。
        </p>
        <h2 class="co-display-8 mb-8 mt-14 border-l-[5px] border-[#ff0033] py-2 pl-4 pr-2 font-bold tracking-normal">
          かんたんビデオチャットの利用方法
        </h2>
        <p class="mb-5 ml-4">
          無料相談を実施する際に「かんたんビデオチャット」というツールを使用します。
        </p>
        <p class="mb-5 ml-4">
          かんたんビデオチャットとは、Google Chromeなどのブラウザ上で利用できるビデオ通話サービスで、特定のURLにアクセスするとすぐに、ビデオ通話を開始することができます。
        </p>
        <p class="mb-5 ml-4">
          かんたんビデオチャットを使うには、下記の2つの準備が必要です。
        </p>
        <ul class="my-5 ml-3 list-inside list-disc pl-5">
          <li class="mb-2">
            マイク
          </li>
          <li class="mb-2">
            ウェブカメラ
          </li>
        </ul>
        <p class="mb-5 ml-4">
          なお、お持ちのPCに<strong>マイクとウェブカメラが内蔵されている</strong>場合は別途ご用意いただく必要はありません。<br>もしお持ちでない場合は、家電量販店などで<a
            href="https://www.amazon.co.jp/gp/product/B00GZAF72U/"
            target="_blank"
          >1000円程度で購入可能</a>です。<br>スマートフォンからご参加される場合は、ご用意いただく必要はありません。
        </p>
        <h2 class="co-display-8 mb-8 mt-14 border-l-[5px] border-[#ff0033] py-2 pl-4 pr-2 font-bold tracking-normal">
          かんたんビデオチャットの使い方
        </h2>
        <p class="mb-5 ml-4">
          ご予約時間となりましたら、かんたんビデオチャットのURLを開いてご入室ください。
        </p>
        <p class="mb-5 ml-4">
          かんたんビデオチャットのURLは、ご予約完了メールに記載されています。メールが見当たらない場合は、<a
            href="https://techacademy.jp/ly/contact"
            target="_blank"
          >サポート</a>までご連絡ください。
        </p>
        <p class="mb-5 ml-4">
          画面を開く際に、カメラとマイクの使用許可を求められることがありますので、その場合は「許可する」をクリックしてください。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/videochat_01.webp"
            class="co-aspectratio-attr mb-4"
            loading="lazy"
          >
        </p>
        <p class="mb-5 ml-4">
          部屋の画面が表示される前に確認画面が出てきます。ここでカメラとマイクの設定が確認できます。ご準備が整いましたら「無料相談を開始する」ボタンをクリックしてご入室ください。
        </p>
        <p class="mb-5 ml-4">
          <img
            src="~/assets/images/Te/TeTutorial/TrialMentoring/videochat_02.webp"
            class="co-aspectratio-attr mb-4"
            loading="lazy"
          >
        </p>
        <h3 class="co-display-10 mb-5 mt-10 font-bold">
          音声や映像が流れない場合
        </h3>
        <p class="mb-5 ml-4">
          下記の内容をお試しください。
        </p>
        <ol class="my-5 ml-3 list-inside list-decimal pl-5">
          <li class="mb-2">
            マイクがちゃんとささっているか確認してみる
          </li>
          <li class="mb-2">
            YouTubeなどで音声流してみて音声が出るか確認し、ミュートになってないかなどチェックする
          </li>
          <li class="mb-2">
            ブラウザを一度終了してから再起動する
          </li>
          <li class="mb-2">
            PCを再起動する（ブラウザの再起動でうまくいかない場合に使用）
          </li>
        </ol>
        <p class="mb-5 ml-4">
          それでもうまく利用できない場合は、画面右側にある「メッセージ」から状況をご連絡いただくか、<a
            href="https://techacademy.jp/ly/contact"
            target="_blank"
          >サポート</a>までお問い合わせください。
        </p>
        <p class="mb-5 ml-4">
          状況によっては再度日程をお取りさせていただく場合がございます。予めご了承ください。
        </p>
      </div>
    </article>
  </div>
</template>

<script lang="ts">
import { scriptTagsByConfig } from '~/utility/scriptTags'
import { metaTags } from '~/utility/constants/metaTags'

const options = {
  oldHead() {
    const scriptTags = scriptTagsByConfig({
      useCase: 'yahoo',
    })
    return {
      title: '無料相談の実施方法 |LINEヤフーテックアカデミー',
      meta: [
        ...metaTags({
          description: '無料相談の実施方法のご案内。',
          twitterTitle: '無料相談の実施方法',
          twitterDescription:
            '無料相談の実施方法のご案内。',
          ogTitle: '無料相談の実施方法',
          ogDescription: '無料相談の実施方法のご案内。',
          ogUrl: '/ly/tutorial/trial-mentoring',
          noIndexNofollow: true,
        }),
      ],
      script: scriptTags.script,
      noscript: scriptTags.noscript,
    }
  },
  async setup() {
    definePageMeta({
      layout: 'yate-simple'
    })
    useHead({
      bodyAttrs: {
        class: 'bg-co-gray-200'
      }
    })
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.co-aspectratio-attr {
  aspect-ratio: attr(width) / attr(height);
}
</style>
